var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.textSection.deleted)?_c('div',[_c('SectionTitleEditor',{attrs:{"sectionTitle":_vm.textSection.sectionTitle,"disabled":_vm.isEditable(_vm.textSection.sectionTitle),"placeholder":_vm.isVersionMode ? '' : undefined,"isFocused":_vm.isFocused(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionTitle
      )},on:{"update:sectionTitle":function($event){return _vm.$emit('update:sectionTitle', $event)},"focus":function($event){return _vm.focusOn(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionTitle
      )},"blur":function($event){return _vm.blurOn(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionTitle
      )}}}),_c('SectionValueEditor',{attrs:{"sectionValue":_vm.textSection.sectionValue,"disabled":_vm.editorIsDisabled,"placeholder":_vm.isVersionMode ? '' : undefined,"isFocused":_vm.isFocused(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionValue
      )},on:{"update:sectionValue":function($event){return _vm.$emit('update:sectionValue', $event)},"focus":function($event){return _vm.focusOn(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionValue
      )},"blur":function($event){return _vm.blurOn(
        _vm.textSection.documentSectionId || _vm.textSection.tempId,
        _vm.EditorFocusElementType.textSectionValue
      )}}},[_c('template',{slot:"tags"},[(_vm.dirtyTagMaps)?_c('TagsEditor',{directives:[{name:"show",rawName:"v-show",value:(
          (function () {
            if (_vm.editorIsDisabled)
              { return _vm.dirtySectionsTagMaps.some(
                function (ref) {
                      var entityId = ref.entityId;

                      return entityId === _vm.textSection.documentSectionId;
                    }
              ); }
            else { return true; }
          })()
        ),expression:"\n          (() => {\n            if (editorIsDisabled)\n              return dirtySectionsTagMaps.some(\n                ({ entityId }) => entityId === textSection.documentSectionId\n              );\n            else return true;\n          })()\n        "}],attrs:{"value":_vm.dirtyTagMaps,"entityId":_vm.entityId,"isEntityIdTemp":_vm.isEntityIdTemp,"entityType":_vm.TagEntityTypeEnum.section,"noBorder":"","disabled":_vm.editorIsDisabled},on:{"input":function($event){return _vm.$emit('update:dirtyTagMaps', $event)}},nativeOn:{"click":function($event){return _vm.blurOn(
            _vm.textSection.documentSectionId || _vm.textSection.tempId,
            _vm.EditorFocusElementType.textSectionValue
          )}}}):_vm._e()],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }