


















































































import { Component, Prop } from 'vue-property-decorator';
import SectionValueEditor from '@/components/editor/SectionEditor/SectionValueEditor.vue';
import SectionTitleEditor from '@/components/editor/SectionEditor/SectionTitleEditor.vue';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { TextSection } from '@/store/modules/documents/types/text-sections.types';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { TagEntityTypeEnum } from '@/jbi-shared/types/document.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { EditorFocusStatus, EditorFocusElementType } from '../Editor.vue';
import Op from 'quill-delta/dist/Op';
import { TextSectionDefaultTitle } from '../../../jbi-shared/types/document.types';

@Component({
  components: {
    SectionValueEditor,
    SectionTitleEditor,
    TagsEditor
  }
})
export default class TextSectionEditor extends mixins(ViewModeMixin) {
  @Prop()
  public textSection!: Partial<TextSection>;
  @Prop()
  public focusStatus!: EditorFocusStatus | null;
  @Prop()
  public focusOn!: (id: number, type: EditorFocusElementType) => void;
  @Prop()
  public blurOn!: (id: number, type: EditorFocusElementType) => void;
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public dirtySectionsTagMaps!: DirtyTagMap[];

  get EditorFocusElementType() {
    return EditorFocusElementType;
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  public isFocused(id: number, type: EditorFocusElementType): boolean {
    return JSON.stringify({ id, type }) === JSON.stringify(this.focusStatus);
  }

  // Disable editing of Default Section Titles
  public isEditable(sectionTitle: Op[]): boolean {
    const title = sectionTitle[0].insert as String;
    const defaultTitles = (Object.values(
      TextSectionDefaultTitle
    ) as string[]).map((title) => title.toLowerCase());
    if (!defaultTitles.includes(title.toLocaleLowerCase())) {
      return false;
    }
    return true;
  }

  get entityId() {
    if (this.textSection.documentSectionId !== 0) {
      return this.textSection.documentSectionId;
    } else {
      return this.textSection.tempId;
    }
  }

  get isEntityIdTemp() {
    return Boolean(this.textSection.tempId);
  }
}
